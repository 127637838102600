<template>
  <div>
    <v-container
      fluid
      class="px-2 py-2"
      style="display: flex; justify-content: center; width: 100vw"
    >
      <div style="display: flex; max-width: 2048px; width: 100%">123</div>
    </v-container>
  </div>
</template>

<script>
import Bus from "@/components/Bus/bus.js";
import User from "@/api/user.js";
import DefaultcoverUrl from "@/assets/e72f0cec97576fa79cb875fd862667a.png";
import { FadeTransition } from "vue2-transitions";
export default {
  name: "Dashboard",
  components: {
    FadeTransition,
  },
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 2,
      },
      pageHeight: 500,
      tradingHistory: [],

      coverLoaded: false,

      currTab: 0,
    };
  },
  mounted() {
    this.pageHeight = window.screen.availHeight;
  },
  methods: {},
  watch: {
    // currTab(val){
    // 	console.log(val);
    // },
  },
};
</script>
